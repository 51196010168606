<template>
    <div class="modify-pass">
        <div class="modify-pass-center">
            <p class="modify-pass-title">修改密码</p>
            <div class="modify-pass-list">
                <el-form :model="passForm" status-icon :rules="passrules" ref="passForm" label-width="0" class="demo-ruleForm">
                    <el-form-item prop="password">
                        <p>旧密码：</p>
                        <el-input type="password" v-model="passForm.password" placeholder="请输入密码" show-password></el-input>
                    </el-form-item>
                    <el-form-item prop="pass">
                        <p>新密码：</p>
                        <el-input type="password" v-model="passForm.pass" placeholder="请输入密码" show-password></el-input>
                    </el-form-item>
                    <el-form-item prop="checkPass" style="margin-bottom:15px;">
                        <p>再次输入：</p>
                        <el-input type="password" v-model="passForm.checkPass" placeholder="请再次输入密码" show-password></el-input>
                    </el-form-item>
                    <!-- <span>密码长度为8-16位字符，包含数字与字母</span> -->
                    <el-form-item style="margin-bottom:0;">
                        <el-button ype="primary" @click="submitPass('passForm')" class="login-button">确认修改</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import { changPassword } from "../../api/personal"
export default {
    data(){
         // 旧密码
        let password = (rule, value, callback) => {
        if (value === "") {
            callback(new Error("请输入密码"))
        } else {
            callback()
        }
        }

        // 新密码
        let validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入新密码"))
            } else {
                callback()
            }
        }

        // 二次验证密码
        let validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.passForm.pass) {
                callback(new Error("两次输入密码不一致!"));
            }else {
                callback()
            }
        }
        return{
            passForm:{
                password:"",
                pass:"",
                checkPass:""
            },
            passrules:{
                password:[{validator: password, trigger: 'change'}],
                pass: [{ validator: validatePass, trigger: 'change' }],
                checkPass: [{ validator: validatePass2, trigger: 'change' }],
            }
        }
    },
    methods:{
        //确认修改
        submitPass(formName){
            this.$refs.passForm.validate(valid => {
                // console.log(valid)
                if(valid){
                    changPassword({
                        oldPassword:this.passForm.password,
                        password:this.passForm.pass,
                        newPassword:this.passForm.checkPass
                    }).then(res=>{
                        if(res.data.code == 20000){
                            this.$router.push("/login");
                            this.$message.success(res.data.message)
                            this.$store.commit('del_token')
                            window.localStorage.clear(); 
                        }else{
                            this.$message.error(res.data.message);
                        } 
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.modify-pass{
    width:100%;
    height:592px;
    background-color:#FFFFFF;
    padding:30px;
    .modify-pass-center{
        width:100%;
        .modify-pass-title{
            color:#000000;
            font-size:20px;
            font-weight:500;
        }
        .modify-pass-list{
            margin-top:20px;
            width:420px;
            p{
                color:#333333;
                font-size:12px;
            }
            span{
                color:#999999;
                font-size:12px;
            }
            /deep/ .el-form-item{
                margin-bottom:10px;
            }
            .login-button{
                width:92px;
                height:36px;
                background: linear-gradient(270deg, #FF6100 0%, #FFB800 100%);
                border-radius:6px;
                display:flex;
                align-items:center;
                justify-content: center;
                color:#FFFFFF;
                font-size:14px;
                border:none;
                margin-left:0;
                margin-top:35px;
            }
        }
    }
}
</style>